import React from 'react';
import { useTranslation } from 'next-i18next';
import Typography from '@mui/material/Typography';
import { FormPageWrapper } from '../FormComponents/FormPageWrapper';
import { FormLogo } from '../FormComponents/FormLogo';

export const PageNotFound = () => {
    const { t } = useTranslation();

    return (
        <FormPageWrapper>
            <FormLogo />
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {t('common:general.page_not_found')}
            </Typography>
        </FormPageWrapper>
    );
};
