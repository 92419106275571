import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { Layout } from '../components/Layout';
import { PageNotFound } from '../components/atoms/PageNotFound';

export const Custom404 = () => {
    const { t } = useTranslation();

    return (
        <Layout title={`Bladebinge - ${t('common:general.page_not_found')}`}>
            <PageNotFound />
        </Layout>
    );
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
    props: await serverSideTranslations(locale, ['cms', 'common', 'legal'])
});

export default Custom404;
